<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <KTCard>
                    <template v-slot:body
                              v-if="$global.hasPermission('walletsview') && $global.hasPermission('walletsstore')">
                        <form @submit.prevent="handleSubmitOperation">
                            <b-row>
                                <b-col sm="6">
                                    <b-card class="border-primary">
                                        <b-card-title class="m-auto text-center">Wallet Balance</b-card-title>
                                        <b-card-body class="m-auto text-center">
                                            <h1 class="card-title-rupees">₹ {{totalWalletAmount}}</h1>
                                        </b-card-body>
                                    </b-card>
                                </b-col>
                                <b-col sm="4">
                                    <b-form-group
                                        label="Amount *"
                                        label-for="price"
                                        :invalid-feedback="formErrors.first('price')"
                                    >
                                        <b-form-input
                                            id="price"
                                            v-model="formFields.price"
                                            type="text"
                                            v-numericOnly.keyup
                                            :state="((formErrors.has('price') ? false : null))"
                                        ></b-form-input>
                                    </b-form-group>
                                    <b-button
                                        size="sm"
                                        type="submit"
                                        variant="primary"
                                        :disabled="global.pendingRequests > 0"
                                    >
                                        <i v-show="global.pendingRequests > 0"
                                           class="fa fa-spin fa-spinner"></i>
                                        <i class="fa fa-save fa-sm"></i>
                                        Top-up Wallet
                                    </b-button>
                                </b-col>
                            </b-row>
                        </form>
                    </template>
                </KTCard
                >
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import KTCard from "@/view/content/Card.vue";
    import Error from "@/core/services/Error";
    import {request} from "@/core/services/Request";
    import {getAuthUser} from "@/util/globalFunction";

    const DEFAULT_FORM_STATE = {
        price: null,
        currency: null,
        _method: 'post',
    }

    export default {
        components: {
            KTCard
            ,
        },
        data() {
            return {
                successStatus: null,
                orderId: null,
                formFields: {...DEFAULT_FORM_STATE},
                formErrors: new Error({}),
                totalWalletAmount: 0,
            }
        },
        methods: {
            async handleSubmitOperation() {
                let self = this;
                this.formErrors = new Error({})
                let Price = (this.formFields.price * 100)
                try {
                    const response = await request({
                        url: `my/wallet/order/create`,
                        method: 'post',
                        data: {
                            ...this.formFields,
                        }
                    })

                    const {data} = response.data
                    if (data) {
                        self.orderId = data
                    }

                    if (self.formFields.price) {
                        var options = {
                            "key": `${process.env.VUE_APP_RAZORPAY_KEY_ID}`,
                            "amount": `${Price}`,
                            "currency": `INR`,
                            "order_id": `${self.orderId}`,
                            "image": "/media/logos/logo1.png",
                            "handler": function (response) {
                                self.handleSubmitData(response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature)
                            },
                            "prefill": {
                                "name": `${getAuthUser().name}`,
                                "email": `${getAuthUser().email}`
                            },
                            "theme": {
                                "color": "#F37254"
                            }
                        };
                        var rzp1 = new Razorpay(options);
                        rzp1.open();
                    } else {
                        self.formErrors = new Error({price: ["The price field is required."]})
                    }


                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }
                    this.$global.handleServerError(error)
                }
            },
            async handleSubmitData(razorpayId, orderId, signature) {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: `my/wallet/create`,
                        method: 'post',
                        data: {
                            ...this.formFields,
                            'razorpay_payment_id': razorpayId,
                            'order_id': orderId,
                            'signature': signature,
                        }
                    })

                    if (this.formFields.price) {
                        this.totalWalletAmount = parseFloat(this.totalWalletAmount + parseFloat(this.formFields.price))
                    }

                    this.formFields = {...DEFAULT_FORM_STATE}

                    if (getAuthUser()) {
                        this.handleDetailOperation(getAuthUser().id)
                    }

                    this.$global.priceAddedToWalletSuccessfully()

                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }
                    this.$global.handleServerError(error)
                }
            },
            async handleDetailOperation(id) {
                try {
                    const response = await request({
                        method: 'get',
                        url: `my/wallet/detail`,
                    })
                    const {data} = response.data
                    if (data.amount) {
                        this.totalWalletAmount = data.amount
                    }
                } catch (e) {
                    this.$global.itemEditFails()
                    this.formFields = {...DEFAULT_FORM_STATE}
                }
            },
        },
        mounted() {
            let externalScript = document.createElement('script')
            externalScript.setAttribute('src', 'https://checkout.razorpay.com/v1/checkout.js')
            document.head.appendChild(externalScript)
            if (getAuthUser().id) {
                this.handleDetailOperation(getAuthUser().id)
            }
        },
        computed: {
            ...mapState([
                'global',
            ]),
        },
    }
</script>


<style scoped>
    .card-title-rupees {
        font-size: 50px;
    }
</style>
